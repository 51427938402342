import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/city.module.scss';
import cityImage from '../assets/images/city-schwerin.webp';
import hamburgImage from '../assets/images/hamburg-city-2.webp';
import berlinImage from '../assets/images/berlin-city.webp';
import muenchenImage from '../assets/images/muenchen-city-2.webp';
import frankfurtImage from '../assets/images/frankfurt-city-2.webp';
import kielImage from '../assets/images/kiel-city-2.webp';
import hannoverImage from '../assets/images/hannover-city-2.webp';
import rostockImage from '../assets/images/rostock-city-2.webp';
import wismarImage from '../assets/images/city-wismar.webp';
import luebeckImage from '../assets/images/city-luebeck.webp';
import neumuensterImage from '../assets/images/neumuenster-city-2.webp';
import flensburgImage from '../assets/images/city-flensburg.webp';
import googlePartner from '../assets/images/googlepartner.webp';
import erecht24 from '../assets/images/erecht24-siegel.webp';

class City extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city
        }
    }
    renderHeadline(city) {
        switch(city) {
            case 'schwerin':
                return '<h2>Webdesign in<br/><span>Schwerin.</span></h2>';
            case 'hamburg':
                return '<h2>Webdesign in<br/><span>Hamburg.</span></h2>';
            case 'berlin':
                return '<h2>Webdesign in<br/><span>Berlin.</span></h2>';
            case 'muenchen':
                return '<h2>Webdesign in<br/><span>München.</span></h2>';
            case 'frankfurt':
                return '<h2>Webdesign in<br/><span>Frankfurt.</span></h2>';
            case 'kiel':
                return '<h2>Webdesign in<br/><span>Kiel.</span></h2>';
            case 'hannover':
                return '<h2>Webdesign in<br/><span>Hannover.</span></h2>';
            case 'rostock':
                return '<h2>Webdesign in<br/><span>Rostock.</span></h2>';
            case 'wismar':
                return '<h2>Webdesign in<br/><span>Wismar.</span></h2>';
            case 'luebeck':
                return '<h2>Webdesign in<br/><span>Lübeck.</span></h2>';
            case 'neumuenster':
                return '<h2>Webdesign in<br/><span>Neumünster.</span></h2>';
            case 'flensburg':
                return '<h2>Webdesign in<br/><span>Flensburg.</span></h2>';
            default:
                return '<h2>Webdesign in<br/><span>Schwerin.</span></h2>';
        }
    }
    renderText(city) {
        switch(city) {
            case 'schwerin':
                return 'Moin Moin! Mitten in der Landeshauptstadt Schwerin entstehen professionelle und wunderschöne Webseiten. Dabei setzen wir unseren Fokus auf höchste Qualität, egal ob im Bereich Webdesign oder Programmierung. Wir kümmern uns neben der Erstellung Ihrer Webseite auch um die Wartung und Pflege. Lassen Sie sich überzeugen und vereinbaren Sie ein kostenloses Beratungsgespräch.';
            case 'hamburg':
                return 'Moin Moin! Mitten in der schönen Hafenstadt Hamburg entstehen professionelle und funktionale Webdesigns. Unsere Stärken liegen dabei auf höchster Qualität für Ihr Webdesign in Hamburg. Von der Gestaltung Ihrer Webseite bis hin zur Wartung Ihrer Webseite sind wir Ihr perfekter Ansprechpartner in Sachen Webdesign. Überzeugen Sie sich selbst und vereinbaren Sie Ihr kostenfreies Beratungsgespräch.';
            case 'berlin':
                return 'Mitten in der Hauptstadt Berlin entickeln wir funktionale & gestalterische Webseiten. Unser Fokus liegt auf höchster Kundenzufriedenheit im Bereich Webdesign Berlin. Wir überzeugen Sie durch moderne Programmierung und Beratung rund um das Thema Webdesign. Überzeugen Sie sich selbst und vereinbaren Sie jetzt ein kostenfreies Beratungsgespräch.'
            case 'muenchen':
                return 'Servus! In der Kulturstadt München kreieren wir einzigartige Webseiten. Unser Webdesign in München verleiht Ihrem Unternehmen nicht nur ein einzigartiges Aushängeschild. Gewinnen Sie überzeugend neue Kunden durch eine professionelle Webseite zur Neukundengewinnung. Vereinbaren Sie ein kostenfreies Beratungsgespräch und erfahren Sie mehr!'
            case 'rostock':
                return 'Moin Moin aus Rostock! Mit einer professionellen und benutzerfreundlichen Webseite gestalten wir Ihr Webdesign in Rostock. Durch langjährige Expertise überzeugen wir Sie nicht nur mit wunderschönen Webseiten. Generieren Sie mit Ihrer Webseite Neukunden für Ihre Dienstleistungen. Als Full-Service Agentur übernehmen wir für Sie alles rundum Webseiten. Egal ob Wartung oder Inhaltspflege.'
            case 'wismar':
                return 'Ahoi aus Wismar! Mit unserem Fokus auf Webdesign in Wismar gestalten wir professionelle und benutzerfreundliche Websites, die Ihre Online-Präsenz auf ein neues Level heben. Unsere langjährige Expertise ermöglicht es uns, Sie nicht nur mit ästhetisch ansprechenden Designs zu beeindrucken, sondern auch mit funktionalen und effektiven Lösungen. Durch gezieltes Webdesign können wir dazu beitragen, dass Ihre Webseite zu einem starken Instrument wird, um Neukunden für Ihre Dienstleistungen zu gewinnen.'
            case 'luebeck':
                return 'Ahoi aus Lübeck! Direkt hier in unserer schönen Stadt arbeiten wir an professionellen und ästhetischen Webseiten. Unser Fokus liegt stets auf höchster Qualität, sei es im Bereich Webdesign oder Programmierung. Neben der Erstellung Ihrer Webseite kümmern wir uns auch um deren Wartung und Pflege. Überzeugen Sie sich selbst und vereinbaren Sie gerne ein kostenloses Beratungsgespräch mit uns.'
            case 'kiel':
                return 'Moin Moin! In der maritimen Stadt Kiel gestalten wir professionelle und beeindruckende Webseiten, die überzeugen. Unser Fokus liegt auf höchster Qualität – von modernem Webdesign bis hin zur individuellen Programmierung. Neben der Erstellung Ihrer Webseite übernehmen wir auch Wartung und Pflege, damit Ihr Online-Auftritt stets glänzt. Überzeugen Sie sich selbst und sichern Sie sich jetzt Ihr kostenloses Beratungsgespräch!'
            case 'neumuenster':
                return 'Moin Moin! In der lebendigen Stadt Neumünster gestalten wir beeindruckende Webseiten, die sowohl funktional als auch ästhetisch überzeugen. Mit einem klaren Fokus auf Qualität bieten wir Ihnen maßgeschneiderte Webdesign-Lösungen und individuelle Programmierung. Zusätzlich zur Erstellung Ihrer Webseite kümmern wir uns um die Wartung und Pflege, sodass Ihr Online-Auftritt immer auf dem neuesten Stand bleibt. Lassen Sie sich von unserer Expertise überzeugen und vereinbaren Sie noch heute Ihr kostenloses Beratungsgespräch!'
            case 'flensburg':
                return 'Moin Moin! In der charmanten Stadt Flensburg erstellen wir beeindruckende Webseiten, die sowohl funktional als auch visuell begeistern. Mit einem klaren Fokus auf höchste Qualität bieten wir Ihnen maßgeschneiderte Webdesign-Lösungen und individuelle Programmierung. Neben der Erstellung Ihrer Webseite sorgen wir auch für die kontinuierliche Wartung und Pflege, damit Ihr Online-Auftritt immer aktuell und bestens gepflegt bleibt. Lassen Sie sich von unserer Expertise überzeugen und vereinbaren Sie noch heute Ihr kostenloses Beratungsgespräch!'
            default:
                return 'Moin Moin! Mitten in der Landeshauptstadt Schwerin entstehen professionelle und wunderschöne Webseiten. Dabei setzen wir unseren Fokus auf höchste Qualität, egal ob im Bereich Webdesign oder Programmierung. Wir kümmern uns neben der Erstellung Ihrer Webseite auch um die Wartung und Pflege. Lassen Sie sich überzeugen und vereinbaren Sie ein kostenloses Beratungsgespräch.';
        }
    }
    renderImage(city) {
        switch(city) {
            case 'schwerin':
                return cityImage;
            case 'hamburg':
                return hamburgImage;
            case 'berlin':
                return berlinImage;
            case 'muenchen':
                return muenchenImage;
            case 'frankfurt':
                return frankfurtImage;
            case 'kiel':
                return kielImage;
            case 'hannover':
                return hannoverImage;
            case 'rostock':
                return rostockImage;
            case 'wismar':
                return wismarImage;
            case 'luebeck':
                return luebeckImage;
            case 'neumuenster':
                return neumuensterImage;
            case 'flensburg':
                return flensburgImage;
            default:
                return cityImage;
        }
    }
    render() { 
        return (
            <section className={cn(styles.cityWrapper)}>
                <div className={cn(styles.cityText)}>
                    <div className={cn(styles.cityTextInner)}>
                        <div className={cn(styles.cityHeadline)} dangerouslySetInnerHTML={{ __html: this.renderHeadline(this.state.city) }}></div>
                        <p dangerouslySetInnerHTML={{ __html: this.renderText(this.state.city) }}></p>
                        <p>
                            <a href="https://cloud.teamleader.eu/erik-ivanov/bookings/u/erik/t/30-min%C3%BCtiger-online-termin-mit-erik-ivanov/" target="_blank" ref="noopener noreferrer" className="btn gold animated" title="Beratungsgespräch vereinbaren">Beratungsgespräch vereinbaren</a>
                            <a href="tel:+4938548597862" className="btn gold tel link" title="Jetzt anrufen">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.325" height="17.328" viewBox="0 0 17.325 17.328">
                                    <path id="call_1_" d="M45.98,49.338a7.288,7.288,0,0,1-3.4-1.16,22.131,22.131,0,0,1-5.346-4,20.287,20.287,0,0,1-4.015-5.344c-1.425-2.592-1.182-3.95-.911-4.531a3.616,3.616,0,0,1,1.418-1.521,6.819,6.819,0,0,1,1.108-.588l.107-.047a1.035,1.035,0,0,1,.849-.077,2.487,2.487,0,0,1,.807.619,13.41,13.41,0,0,1,2.018,2.995,2.984,2.984,0,0,1,.4,1.227,1.938,1.938,0,0,1-.493,1.153c-.051.069-.1.135-.15.2-.294.387-.359.5-.316.7a7.337,7.337,0,0,0,1.787,2.65,7.033,7.033,0,0,0,2.619,1.743c.208.044.322-.023.721-.328.057-.044.116-.089.178-.134a1.949,1.949,0,0,1,1.17-.524h0a2.8,2.8,0,0,1,1.232.432,14.776,14.776,0,0,1,2.984,2,2.486,2.486,0,0,1,.621.8,1.043,1.043,0,0,1-.077.851c-.014.032-.03.067-.047.106a6.826,6.826,0,0,1-.591,1.105,3.617,3.617,0,0,1-1.523,1.415A2.608,2.608,0,0,1,45.98,49.338Z" transform="translate(-32.098 -32.009)" fill="#dbac52"/>
                                </svg>
                                0385 48597862
                            </a>
                        </p>
                        <ul className={cn(styles.cityLogos)}>
                            <li>
                                <img src={googlePartner} alt="Google Partner Logo"/>
                            </li>
                            <li>
                                <img src={erecht24} alt="eRecht24 Agenturpartner"/>
                            </li>
                            <li>    
                                <div className="pe-richsnippets"></div>
                                <script type="text/javascript" data-skip-lazy="" src="https://www.provenexpert.com/widget/richsnippet.js?u=2VmZ3ZGZ4tGp5RGA4ZQAl5zolxQAhIQA&v=2" async></script>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={cn(styles.cityImage)}>
                    <img src={this.renderImage(this.state.city)} alt="City Image"/>
                </div>
            </section>
        );
    }
}
 
export default City;