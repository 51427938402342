import React, { Component } from 'react';
import cn from 'classnames';
import styles from '../scss/webdesign.module.scss';
import schwerinMarkt from '../assets/images/city-schwerin-markt.webp';
import hamburgImage from '../assets/images/hamburg-city.webp';
import berlinImage from '../assets/images/berlin-city-2.webp';
import kielImage from '../assets/images/kiel-city.webp';
import rostockImage from '../assets/images/rostock-city.webp';
import hannoverImage from '../assets/images/hannover-city.webp';
import frankfurtImage from '../assets/images/frankfurt-city.webp';
import muenchenImage from '../assets/images/muenchen-city.webp';
import wismarImage from '../assets/images/city-wismar-hafen.webp';
import luebeckImage from '../assets/images/city-luebeck-tor.webp';
import neumuensterImage from '../assets/images/neumuenster-city.webp';
import flensburgImage from '../assets/images/city-flensburg-2.webp';
import googlePartner from '../assets/images/googlepartner.webp';
import googleMaps from '../assets/images/google-maps-screenshot.webp';
import wordpress from '../assets/logos/wordpress-logo.svg';
import dsgvo from '../assets/logos/dsgvo-ready-banner.svg';

class WebdesignBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.city
        }
    }
    renderImage(city) {
        switch(city) {
            case 'schwerin':
                return schwerinMarkt;
            case 'hamburg':
                return hamburgImage;
            case 'berlin':
                return berlinImage;
            case 'kiel':
                return kielImage;
            case 'rostock':
                return rostockImage;
            case 'hannover':
                return hannoverImage;
            case 'muenchen':
                return muenchenImage;
            case 'frankfurt':
                return frankfurtImage;
            case 'wismar':
                return wismarImage;
            case 'luebeck':
                return luebeckImage;
            case 'neumuenster':
                return neumuensterImage;
            case 'flensburg':
                return flensburgImage;
            default:
                return schwerinMarkt;
        }
    }
    renderText1(city) {
        switch(city) {
            case 'schwerin':
                return (
                    <>
                        <h2>Erfahren Sie mehr über Webdesign in Schwerin</h2>
                        <p>
                            <strong>
                                Unsere professionelle Agentur für Webdesign in Schwerin verschafft Ihnen die nötigen Schritte, damit Sie erfolgreich im Netz gefunden werden.
                            </strong>
                        </p>
                        <p>
                            <ul>
                                <li><strong>Smartphones</strong>: Die meisten Besucher Ihrer Webseite schauen sich Ihre Webseite über ein Smartphone an. Eine Gestaltung Ihrer Webseite für unterschiedliche Endgeräte gewährleistet nicht nur optimale Darstellung, sondern auch Vorteile bei den Google Suchergebnissen. Wussten Sie, dass Webseiten, die nicht für mobile Endgeräte optimiert sind - zu schlechteren Plätzen in der Google Suche führen?</li>
                                <li><strong>Performance</strong>: Gutes Aussehen ist nicht alles. Durch unsere performante technische Umsetzung sorgen wir für die Webseite Ihres Unternehmens aus Schwerin für geringste Ladezeiten. Denn: Warten bis eine Webseite vollständig geladen ist sorgt für genervte Besucher.</li>
                                <li><strong>Betreuung</strong>: Ihre Webseite ist nun online und kann von Jedermann aufgerufen werden. Doch was nützt Ihre nagelneue Webseite, wenn sie nicht im Internet gefunden wird oder die Konkurrenz über Ihrem Suchergebnis steht? Ich biete Ihnen professionelle Suchmaschinenoptimierung (SEO), Suchmaschinenwerbung über Google Analytics und Werbung auf Sozialen Netzwerken. Überlassen Sie Werbung jungen und innovativen Leuten. Denn durch Innovation wird Ihre nächste Werbekampagne erfolgreich – Versprochen!</li>
                            </ul>
                        </p>
                    </>
                )
            case 'hamburg':
                return (
                    <>
                        <h2>Wir konzipieren und gestalten Ihr Webdesign in Hamburg</h2>
                        <p>
                            <strong>
                                Aussehen ist nicht alles! Durch unsere langjährige Expertise im Bereich Webdesign Hamburg sorgen wir dafür, dass Sie im Netz gefunden werden.
                            </strong>
                        </p>
                        <p>
                            <ul>
                                <li><strong>Responsive Darstellung</strong>: Ihre Webseite muss funktionieren - darauf legen wir großen Fokus. Durch Optimierungen für verschiedenste Endgeräte, sorgen wir dafür, dass Ihre Webseite stets perfekt aussieht, sowohl auf Smartphones als auch auf Desktop-Computern. Sollte Ihre Webseite nicht für verschiedenste Endgeräte optimiert sein, müssen Sie mit Nachteilen beim Google-Ranking rechnen.</li>
                                <li><strong>Performance</strong>: Gutes Aussehen ist nicht alles. Durch unsere performante technische Umsetzung sorgen wir für die Webseite Ihres Unternehmens aus Schwerin für geringste Ladezeiten. Denn: Warten bis eine Webseite vollständig geladen ist sorgt für genervte Besucher.</li>
                                <li><strong>Betreuung</strong>: Ihre Webseite ist nun online und kann von Jedermann aufgerufen werden. Doch was nützt Ihre nagelneue Webseite, wenn sie nicht im Internet gefunden wird oder die Konkurrenz über Ihrem Suchergebnis steht? Ich biete Ihnen professionelle Suchmaschinenoptimierung (SEO), Suchmaschinenwerbung über Google Analytics und Werbung auf Sozialen Netzwerken. Überlassen Sie Werbung jungen und innovativen Leuten. Denn durch Innovation wird Ihre nächste Werbekampagne erfolgreich – Versprochen!</li>
                            </ul>
                        </p>
                    </>
                )
            case 'rostock':
                return (
                    <>
                        <h2>Rundum versorgt mit Webdesign in Rostock</h2>
                        <p>
                            <strong>
                                Mit unserer langjährigen Expertise sind wir Ihr richtiger Ansprechpartner für Webdesign in Rostock. Wir steigern Ihre Sichtbarkeit im Netz.
                            </strong>
                        </p>
                        <p>
                            <ul>
                                <li><strong>Responsive Design</strong>: Top Performance auf allen Geräten. Wir sorgen dafür, dass Ihre neue Webseite auf allen Geräten perfekt aussieht. Egal ob auf Smartphones oder Desktop-Computern - Ihre Webseite passt sich allen Displaygrößen an.</li>
                                <li><strong>Performance</strong>: Wussten Sie dass eine schnelle Webseite sich positiv auf Ihr Ranking bei Google auswirkt? Bereits bei der technischen Umsetzung Ihrer Webseite achten wir fokussiert auf die Ladezeiten Ihrer Webseite.</li>
                                <li><strong>Wartung</strong>: Eine Webseite muss gepflegt und gewartet werden. Wir stehen Ihnen zur Seite, wenn Sie Änderungen oder neue Inhalte wünschen. Darf es etwas mehr sein? Wir bieten Ihnen auf Wunsch Suchmaschinenoptimierung (SEO) und Online Marketing an.</li>
                            </ul>
                        </p>
                    </>
                )
            case 'berlin':
                return (
                    <>
                        <h2>Professionelles Webdesign in Berlin: Ihre Erfolgsformel</h2>
                        <p>
                            <strong>
                                Sie sind auf der Suche nach einem zuverlässigen Partner für erstklassiges Webdesign in Berlin? Bei uns sind Sie an der richtigen Adresse! Mit unserer langjährigen Expertise und Leidenschaft für kreative Lösungen steigern wir Ihre Online-Sichtbarkeit und bringen Ihr Unternehmen in der Hauptstadt zum Strahlen.
                            </strong>
                        </p>
                        <p>
                            <ul>
                                <li><strong>Responsive Websites</strong>: Perfekte Darstellung auf jedem Gerät Ihre neue Webseite soll auf allen Geräten eine beeindruckende Performance liefern? Kein Problem! Unser Team sorgt dafür, dass Ihre Webseite auf Smartphones, Tablets und Desktop-Computern gleichermaßen fantastisch aussieht. Dank unserem responsiven Design passt sich Ihre Seite optimal an die Displaygröße an.</li>
                                <li><strong>Schnelligkeit zählt</strong>: Optimale Performance für Top-Rankings Wussten Sie, dass die Ladezeit Ihrer Webseite einen direkten Einfluss auf Ihr Google-Ranking hat? Bei uns steht Performance an erster Stelle. Bereits bei der technischen Umsetzung Ihrer Webseite achten wir akribisch auf die Optimierung der Ladezeiten, um Ihnen den entscheidenden Vorsprung zu verschaffen.</li>
                                <li><strong>Komplettservice für Ihren Erfolg</strong>: Eine erfolgreiche Webseite benötigt kontinuierliche Pflege und Wartung. Wir unterstützen Sie gerne dabei! Ob Änderungen an Inhalten oder die Umsetzung einer umfassenden Suchmaschinenoptimierung (SEO) und Online-Marketing-Strategie - wir bieten Ihnen einen maßgeschneiderten Komplettservice, damit Sie sich ganz auf Ihr Kerngeschäft konzentrieren können.</li>
                            </ul>
                        </p>
                        <p>Setzen Sie auf uns, Ihre Experten für Webdesign in Berlin, und sichern Sie sich einen starken Auftritt im digitalen Raum. Kontaktieren Sie uns noch heute, um Ihre individuellen Anforderungen zu besprechen und Ihre Online-Ziele zu erreichen.</p>
                    </>
                )
            case 'wismar':
                return (
                    <>
                        <h2>Entdecken Sie das Geheimnis herausragenden Webdesigns in Wismar</h2>
                        <p>
                            <strong>
                            Sie sind auf der Suche nach einem vertrauenswürdigen Partner für herausragendes Webdesign in Wismar? Dann sind Sie bei uns genau richtig! Mit unserer langjährigen Erfahrung und unserem kreativen Engagement verbessern wir Ihre Online-Präsenz und bringen Ihr Unternehmen in Wismar zum Erstrahlen.
                            </strong>
                        </p>
                        <p>
                            <ul>
                                <li><strong>Responsive Websites</strong>: Eine makellose Darstellung auf sämtlichen Geräten ist Ihnen wichtig? Keine Sorge! Unser Team garantiert, dass Ihre Webseite sowohl auf Smartphones, Tablets als auch Desktop-Computern eine beeindruckende Leistung bietet. Mit unserem responsiven Design wird Ihre Seite optimal an die Bildschirmgröße angepasst, damit sie stets perfekt aussieht.</li>
                                <li><strong>Schnelligkeit zählt</strong>: Eine optimale Performance für erstklassige Platzierungen ist Ihnen wichtig? Dann sind Sie bei uns genau richtig! Wir wissen, dass die Ladezeit Ihrer Webseite einen direkten Einfluss auf Ihr Ranking bei Google hat. Deshalb legen wir bereits bei der technischen Umsetzung Ihrer Webseite größten Wert auf die Optimierung der Ladezeiten. Dadurch verschaffen wir Ihnen den entscheidenden Vorsprung in den Suchergebnissen.</li>
                                <li><strong>Komplettservice für Ihren Erfolg</strong>: Die kontinuierliche Pflege und Wartung einer erfolgreichen Webseite sind entscheidend. Wir stehen Ihnen dabei gerne zur Seite! Egal, ob es um die Aktualisierung von Inhalten geht oder um die Implementierung einer umfassenden Suchmaschinenoptimierung (SEO) und Online-Marketing-Strategie - wir bieten Ihnen einen maßgeschneiderten Komplettservice. So können Sie sich ganz auf Ihr Kerngeschäft konzentrieren, während wir den Erfolg Ihrer Webseite sicherstellen.</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie auf uns, Ihre Spezialisten für Webdesign in Wismar, und sichern Sie sich einen beeindruckenden Auftritt im digitalen Raum. Kontaktieren Sie uns noch heute, um Ihre spezifischen Anforderungen zu besprechen und Ihre Online-Ziele zu verwirklichen.</p>
                    </>
                )
            case 'luebeck':
                return (
                    <>
                        <h2>Entdecken Sie das Geheimnis herausragenden Webdesigns in Lübeck</h2>
                        <p>
                            <strong>
                            Sie sind auf der Suche nach einem vertrauenswürdigen Partner für herausragendes Webdesign in Lübeck? Dann sind Sie bei uns genau richtig! Mit unserer langjährigen Erfahrung und unserem kreativen Engagement verbessern wir Ihre Online-Präsenz und bringen Ihr Unternehmen in Lübeck zum Erstrahlen.
                            </strong>
                        </p>
                        <p>
                            <ul>
                                <li><strong>Responsive Websites</strong>: Erhalten Sie eine perfekte Darstellung auf sämtlichen Geräten mit unserem Webdesign in Lübeck! Unsere responsiven Websites gewährleisten eine beeindruckende Leistung auf Smartphones, Tablets und Desktop-Computern. Ihr Webauftritt wird optimal an die Bildschirmgröße angepasst, sodass er stets makellos aussieht.</li>
                                <li><strong>Schnelligkeit zählt</strong>: Zudem legen wir großen Wert auf schnelle Ladezeiten, da diese einen direkten Einfluss auf Ihr Google-Ranking haben. Durch die Optimierung der Ladezeiten sichern wir Ihnen einen entscheidenden Vorteil in den Suchergebnissen.</li>
                                <li><strong>Komplettservice für Ihren Erfolg</strong>: Unser Komplettservice für Ihren Erfolg umfasst auch die kontinuierliche Pflege und Wartung Ihrer Webseite. Von der Aktualisierung von Inhalten bis hin zur Implementierung einer umfassenden Suchmaschinenoptimierung und Online-Marketing-Strategie stehen wir Ihnen zur Seite. So können Sie sich ganz auf Ihr Kerngeschäft konzentrieren, während wir den Erfolg Ihrer Webseite sicherstellen.</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie auf uns, Ihre Experten für Webdesign in Lübeck, und sichern Sie sich einen herausragenden Auftritt im digitalen Raum. Kontaktieren Sie uns noch heute, um Ihre individuellen Anforderungen zu besprechen und Ihre Online-Ziele zu erreichen.</p>
                    </>
                )
            case 'kiel':
                return (
                    <>
                        <h2>Professionelles Webdesign für Ihren Erfolg in Kiel</h2>
                        <p>
                            <strong>
                            Sie suchen einen zuverlässigen Partner für exzellentes Webdesign in Kiel? Dann sind Sie bei uns genau richtig! Mit unserer langjährigen Erfahrung und kreativen Expertise steigern wir Ihre Online-Präsenz und sorgen dafür, dass Ihr Unternehmen in Kiel online glänzt.
                            </strong>
                        </p>
                        <p>
                            <ul>
                                <li><strong>Responsive Webdesign</strong>: Mit uns erhalten Sie eine Webseite, die auf allen Geräten perfekt aussieht! Unsere responsiven Designs sorgen für eine einwandfreie Darstellung auf Smartphones, Tablets und Desktop-Computern. Ihre Seite wird auf jede Bildschirmgröße optimiert und wirkt stets professionell.</li>
                                <li><strong>Schnelligkeit ist entscheidend</strong>: Wir achten auf schnelle Ladezeiten, da diese direkt Ihr Google-Ranking beeinflussen. Durch unsere Optimierung sorgen wir dafür, dass Ihre Webseite in den Suchergebnissen ganz oben mitspielt.</li>
                                <li><strong>Rundum-Service für Ihren Erfolg</strong>: Unser Service endet nicht bei der Erstellung Ihrer Webseite. Wir bieten Ihnen kontinuierliche Wartung und Pflege, regelmäßige Updates, SEO-Optimierung und maßgeschneiderte Online-Marketing-Strategien. So können Sie sich auf Ihr Geschäft konzentrieren, während wir den Erfolg Ihrer Webseite sichern.</li>
                            </ul>
                        </p>
                        <p>Setzen Sie auf uns als Ihre Experten für Webdesign in Kiel und heben Sie sich mit einem herausragenden Online-Auftritt ab. Kontaktieren Sie uns noch heute, um Ihre Wünsche zu besprechen und Ihre digitalen Ziele zu erreichen.</p>
                    </>
                )
            case 'neumuenster':
                return (
                    <>
                        <h2>Professionelles Webdesign für Ihren Erfolg in Neumünster</h2>
                        <p>
                            <strong>
                            Sie suchen einen zuverlässigen Partner für exzellentes Webdesign in Neumünster? Dann sind Sie bei uns genau richtig! Mit unserer langjährigen Erfahrung und kreativen Expertise steigern wir Ihre Online-Präsenz und sorgen dafür, dass Ihr Unternehmen in Neumünster online glänzt.
                            </strong>
                        </p>
                        <p>
                            <ul>
                                <li><strong>Responsive Webdesign</strong>: Mit uns erhalten Sie eine Webseite, die auf allen Geräten perfekt aussieht! Unsere responsiven Designs sorgen für eine einwandfreie Darstellung auf Smartphones, Tablets und Desktop-Computern. Ihre Seite wird auf jede Bildschirmgröße optimiert und wirkt stets professionell.</li>
                                <li><strong>Schnelligkeit ist entscheidend</strong>: Wir achten auf schnelle Ladezeiten, da diese direkt Ihr Google-Ranking beeinflussen. Durch unsere Optimierung sorgen wir dafür, dass Ihre Webseite in den Suchergebnissen ganz oben mitspielt.</li>
                                <li><strong>Rundum-Service für Ihren Erfolg</strong>: Unser Service endet nicht bei der Erstellung Ihrer Webseite. Wir bieten Ihnen kontinuierliche Wartung und Pflege, regelmäßige Updates, SEO-Optimierung und maßgeschneiderte Online-Marketing-Strategien. So können Sie sich auf Ihr Geschäft konzentrieren, während wir den Erfolg Ihrer Webseite sichern.</li>
                            </ul>
                        </p>
                        <p>Setzen Sie auf uns als Ihre Experten für Webdesign in Neumünster und heben Sie sich mit einem herausragenden Online-Auftritt ab. Kontaktieren Sie uns noch heute, um Ihre Wünsche zu besprechen und Ihre digitalen Ziele zu erreichen.</p>
                    </>
                )
            case 'flensburg':
                return (
                    <>
                        <h2>Erstklassiges Webdesign für Ihren Erfolg in Flensburg</h2>
                        <p>
                            Sie suchen einen kompetenten Partner für professionelles Webdesign in Flensburg? Dann sind Sie bei uns genau richtig! Mit unserer langjährigen Erfahrung und kreativen Expertise helfen wir Ihnen dabei, Ihre Online-Präsenz zu stärken und Ihr Unternehmen in Flensburg ins digitale Rampenlicht zu rücken.
                        </p>
                        <p>
                            <ul>
                                <li><strong>Responsive Webdesign</strong>: Wir sorgen dafür, dass Ihre Webseite auf allen Geräten – ob Smartphone, Tablet oder Desktop – perfekt aussieht. Mit unseren responsiven Designs bieten wir eine benutzerfreundliche Darstellung auf allen Bildschirmgrößen.</li>
                                <li><strong>Optimale Ladegeschwindigkeit</strong>: Schnelle Ladezeiten sind nicht nur wichtig für die Nutzererfahrung, sondern auch für Ihr Google-Ranking. Wir optimieren Ihre Webseite, damit sie schnell lädt und Sie in den Suchergebnissen ganz vorne mitspielen.</li>
                                <li><strong>Komplettservice für Ihren Erfolg</strong>: Unser Service endet nicht bei der Erstellung Ihrer Webseite. Wir bieten regelmäßige Wartung, Updates, SEO-Optimierung und maßgeschneiderte Online-Marketing-Strategien, damit Sie sich voll und ganz auf Ihr Geschäft konzentrieren können, während wir den Erfolg Ihrer Webseite sicherstellen.</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie auf uns als Ihre Experten für Webdesign in Flensburg. Kontaktieren Sie uns noch heute, um Ihre Wünsche zu besprechen und Ihre digitalen Ziele zu verwirklichen.</p>
                    </>
                )
        }
    }
    renderText2(city) {
        switch(city) {
            case 'schwerin':
                return (
                    <>
                        <h2>Das Erfolgsrezept für ein gelungenes Webdesign in Schwerin</h2>
                        <p>
                            Sie suchen einen zuverlässigen Partner für Ihre neue Webseite? Die Wahl des richtigen Webdesigners ist entscheidend. Mit meinem erfahrenen Experten-Team begleite ich Sie Schritt für Schritt auf dem Weg zu Ihrer perfekten Webseite. Bei uns erhalten Sie nicht nur ein beliebiges Design, sondern:
                        </p>
                        <p>
                            <ul>
                                <li>Durchdachte Benutzerfreundlichkeit (UI/UX)</li>
                                <li>Modernes und individuelles Design</li>
                                <li>Schnelle Ladezeiten und optimierter Quelltext</li>
                                <li>Professionelle Suchmaschinenoptimierung (SEO)</li>
                                <li>Responsive Webdesign</li>
                                <li>Aktuelle Programmierstandards</li>
                                <li>Eine beeindruckende Visitenkarte für Ihr Unternehmen</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie uns, Ihrem Experten für erstklassiges Webdesign Schwerin. Kontaktieren Sie uns noch heute und lassen Sie uns Ihre Wünsche besprechen. Gemeinsam schaffen wir eine Webseite, die nicht nur schön anzusehen ist, sondern auch Ihren Besuchern eine optimale Benutzererfahrung bietet.</p>
                    </>
                )
            case 'hamburg':
                return (
                    <>
                        <h2>Das Erfolgsrezept für Webdesign in Hamburg</h2>
                        <p>
                            Sie suchen einen zuverlässigen Partner für Ihre neue Webseite? Die Wahl des richtigen Webdesigners ist entscheidend. Mit meinem erfahrenen Experten-Team begleite ich Sie Schritt für Schritt auf dem Weg zu Ihrer perfekten Webseite. Bei uns erhalten Sie nicht nur ein beliebiges Design, sondern:
                        </p>
                        <p>
                            <ul>
                                <li>Durchdachte Benutzerfreundlichkeit (UI/UX)</li>
                                <li>Modernes und individuelles Design</li>
                                <li>Schnelle Ladezeiten und optimierter Quelltext</li>
                                <li>Professionelle Suchmaschinenoptimierung (SEO)</li>
                                <li>Responsive Webdesign</li>
                                <li>Aktuelle Programmierstandards</li>
                                <li>Eine beeindruckende Visitenkarte für Ihr Unternehmen</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie uns, Ihrem Experten für erstklassiges Webdesign in Hamburg. Kontaktieren Sie uns noch heute und lassen Sie uns Ihre Wünsche besprechen. Gemeinsam schaffen wir eine Webseite, die nicht nur schön anzusehen ist, sondern auch Ihren Besuchern eine optimale Benutzererfahrung bietet.</p>
                    </>
                )
            case 'rostock':
                return (
                    <>
                        <h2>Das Erfolgsrezept für ein gelungenes Webdesign in Rostock</h2>
                        <p>
                            Sie suchen einen zuverlässigen Partner für Ihre neue Webseite? Die Wahl des richtigen Webdesigners ist entscheidend. Mit meinem erfahrenen Experten-Team begleite ich Sie Schritt für Schritt auf dem Weg zu Ihrer perfekten Webseite. Bei uns erhalten Sie nicht nur ein beliebiges Design, sondern:
                        </p>
                        <p>
                            <ul>
                                <li>Durchdachte Benutzerfreundlichkeit (UI/UX)</li>
                                <li>Modernes und individuelles Design</li>
                                <li>Schnelle Ladezeiten und optimierter Quelltext</li>
                                <li>Professionelle Suchmaschinenoptimierung (SEO)</li>
                                <li>Responsive Webdesign</li>
                                <li>Aktuelle Programmierstandards</li>
                                <li>Eine beeindruckende Visitenkarte für Ihr Unternehmen</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie uns, Ihrem Experten für erstklassiges Webdesign in Rostock. Kontaktieren Sie uns noch heute und lassen Sie uns Ihre Wünsche besprechen. Gemeinsam schaffen wir eine Webseite, die nicht nur schön anzusehen ist, sondern auch Ihren Besuchern eine optimale Benutzererfahrung bietet.</p>
                    </>
                )
            case 'wismar':
                return (
                    <>
                        <h2>Das Erfolgsrezept für ein gelungenes Webdesign in Wismar</h2>
                        <p>
                            Sie suchen einen zuverlässigen Partner für Ihre neue Webseite? Die Wahl des richtigen Webdesigners ist entscheidend. Mit meinem erfahrenen Experten-Team begleite ich Sie Schritt für Schritt auf dem Weg zu Ihrer perfekten Webseite. Bei uns erhalten Sie nicht nur ein beliebiges Design, sondern:
                        </p>
                        <p>
                            <ul>
                                <li>Durchdachte Benutzerfreundlichkeit (UI/UX)</li>
                                <li>Modernes und individuelles Design</li>
                                <li>Schnelle Ladezeiten und optimierter Quelltext</li>
                                <li>Professionelle Suchmaschinenoptimierung (SEO)</li>
                                <li>Responsive Webdesign</li>
                                <li>Aktuelle Programmierstandards</li>
                                <li>Eine beeindruckende Visitenkarte für Ihr Unternehmen</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie uns, Ihrem Experten für erstklassiges Webdesign in Wismar. Kontaktieren Sie uns noch heute und lassen Sie uns Ihre Wünsche besprechen. Gemeinsam schaffen wir eine Webseite, die nicht nur schön anzusehen ist, sondern auch Ihren Besuchern eine optimale Benutzererfahrung bietet.</p>
                    </>
                )
            case 'luebeck':
                return (
                    <>
                        <h2>Das Erfolgsrezept für ein gelungenes Webdesign in Lübeck</h2>
                        <p>
                            Sie suchen einen zuverlässigen Partner für Ihr neues Webdesign in Lübeck? Die Wahl des richtigen Webdesigners ist entscheidend. Mit unserem erfahrenen Expertenteam begleiten wir Sie Schritt für Schritt auf dem Weg zu Ihrer perfekten Webseite. Bei uns erhalten Sie nicht nur ein beliebiges Design, sondern:
                        </p>
                        <p>
                            <ul>
                                <li>Durchdachte Benutzerfreundlichkeit (UI/UX)</li>
                                <li>Modernes und individuelles Design</li>
                                <li>Schnelle Ladezeiten und optimierter Quelltext</li>
                                <li>Professionelle Suchmaschinenoptimierung (SEO)</li>
                                <li>Responsive Webdesign</li>
                                <li>Aktuelle Programmierstandards</li>
                                <li>Eine beeindruckende Visitenkarte für Ihr Unternehmen</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie uns, Ihrem Experten für erstklassiges Webdesign in Lübeck. Kontaktieren Sie uns noch heute und lassen Sie uns Ihre Wünsche besprechen. Gemeinsam schaffen wir eine Webseite, die nicht nur schön anzusehen ist, sondern auch Ihren Besuchern eine optimale Benutzererfahrung bietet.</p>
                    </>
                )
            case 'kiel':
                return (
                    <>
                        <h2>Ihr Erfolg mit professionellem Webdesign in Kiel</h2>
                        <p>
                            Sie suchen einen erfahrenen Partner für ein herausragendes Webdesign in Kiel? Mit unserem Expertenteam stehen wir Ihnen von der ersten Idee bis zum fertigen Online-Auftritt zur Seite. Wir bieten Ihnen mehr als nur ein schönes Design – bei uns erhalten Sie:
                        </p>
                        <p>
                            <ul>
                                <li>Optimierte Benutzerfreundlichkeit (UI/UX)</li>
                                <li>Modernes, einzigartiges Design, das zu Ihrem Unternehmen passt</li>
                                <li>Schnelle Ladezeiten und sauberer, optimierter Code</li>
                                <li>Effektive Suchmaschinenoptimierung (SEO)</li>
                                <li>Responsive Design für alle Endgeräte</li>
                                <li>Die neuesten Programmierstandards für höchste Qualität</li>
                                <li>Eine beeindruckende digitale Visitenkarte für Ihr Unternehmen</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie auf uns, Ihre Experten für erstklassiges Webdesign in Kiel. Kontaktieren Sie uns noch heute, um Ihre Wünsche und Ideen zu besprechen. Gemeinsam schaffen wir eine Webseite, die nicht nur ästhetisch begeistert, sondern auch eine exzellente Nutzererfahrung bietet und Ihr Unternehmen erfolgreich online positioniert.</p>
                    </>
                )
            case 'neumuenster':
                return (
                    <>
                        <h2>Erfolgreiches Webdesign für Ihr Unternehmen in Neumünster</h2>
                        <p>
                            Auf der Suche nach einem kompetenten Partner für professionelles Webdesign in Neumünster? Wir begleiten Sie von der ersten Idee bis zum perfekten Online-Auftritt. Bei uns erhalten Sie nicht nur ein optisch ansprechendes Design, sondern eine maßgeschneiderte Lösung, die Ihr Unternehmen optimal präsentiert:
                        </p>
                        <p>
                            <ul>
                                <li><strong>Benutzerfreundlichkeit (UI/UX)</strong>: Wir gestalten Ihre Webseite so, dass sie für Ihre Besucher intuitiv und einfach zu bedienen ist.</li>
                                <li><strong>Individuelles Design</strong>: Ihr Design wird einzigartig und passt perfekt zu Ihrem Unternehmen und Ihrer Zielgruppe.</li>
                                <li><strong>Schnelle Ladezeiten</strong>: Durch optimierten Code sorgen wir dafür, dass Ihre Webseite schnell lädt und eine hervorragende Performance bietet.</li>
                                <li><strong>Suchmaschinenoptimierung (SEO)</strong>: Wir optimieren Ihre Webseite für Suchmaschinen, damit Sie besser gefunden werden und in den Google-Rankings steigen.</li>
                                <li><strong>Responsive Design</strong>: Ihre Webseite wird auf allen Geräten – vom Smartphone bis zum Desktop – optimal dargestellt.</li>
                                <li><strong>Moderne Programmierung</strong>: Wir setzen auf die neuesten Technologien, um Ihnen die höchste Qualität und eine langfristige Nutzbarkeit zu garantieren.</li>
                                <li><strong>Digitale Visitenkarte</strong>: Ihre Webseite wird zum perfekten Online-Auftritt und hinterlässt bei Ihren Besuchern einen bleibenden Eindruck.</li>
                            </ul>
                        </p>
                        <p>Setzen Sie auf uns als Ihre Experten für Webdesign in Neumünster. Kontaktieren Sie uns noch heute, um Ihre Ideen zu besprechen und gemeinsam eine Webseite zu entwickeln, die nicht nur optisch überzeugt, sondern auch die Nutzererfahrung optimiert und Ihr Unternehmen erfolgreich positioniert.</p>
                    </>
                )
            case 'flensburg':
                return (
                    <>
                        <h2>Erfolgreiches Webdesign für Ihr Unternehmen in Flensburg</h2>
                        <p>
                            Auf der Suche nach einem erfahrenen Partner für professionelles Webdesign in Flensburg? Wir begleiten Sie vom ersten Konzept bis zum fertigen Online-Auftritt. Bei uns erhalten Sie mehr als nur ein ansprechendes Design – wir bieten Ihnen eine maßgeschneiderte Lösung, die Ihr Unternehmen optimal online präsentiert:
                        </p>
                        <p>
                            <ul>
                                <li><strong>Benutzerfreundlichkeit (UI/UX)</strong>: Wir gestalten Ihre Webseite so, dass sie für Ihre Besucher einfach zu navigieren und benutzerfreundlich ist.</li>
                                <li><strong>Individuelles Design</strong>: Ihr Design wird einzigartig und auf Ihre Marke sowie Zielgruppe abgestimmt, um Ihre Identität perfekt widerzuspiegeln.</li>
                                <li><strong>Schnelle Ladezeiten</strong>: Mit einem optimierten Code stellen wir sicher, dass Ihre Webseite schnell lädt und eine hervorragende Performance liefert.</li>
                                <li><strong>Suchmaschinenoptimierung (SEO)</strong>: Wir optimieren Ihre Webseite gezielt für Suchmaschinen, damit Sie von potenziellen Kunden besser gefunden werden und in den Google-Rankings höher steigen.</li>
                                <li><strong>Responsive Design</strong>: Ihre Webseite wird auf allen Geräten – vom Smartphone bis zum Desktop – perfekt angezeigt und bietet eine optimale Nutzererfahrung.</li>
                                <li><strong>Moderne Programmierung</strong>: Wir setzen auf aktuelle Technologien und Trends, um Ihre Webseite zukunftssicher und benutzerfreundlich zu gestalten.</li>
                                <li><strong>Digitale Visitenkarte</strong>: Ihre Webseite wird zur perfekten Online-Präsentation, die bei Ihren Besuchern einen bleibenden Eindruck hinterlässt.</li>
                            </ul>
                        </p>
                        <p>Vertrauen Sie auf uns als Ihre Experten für Webdesign in Flensburg. Kontaktieren Sie uns noch heute, um Ihre Ideen zu besprechen und gemeinsam eine Webseite zu entwickeln, die nicht nur optisch überzeugt, sondern auch eine hervorragende Nutzererfahrung bietet und Ihr Unternehmen erfolgreich im digitalen Raum positioniert.</p>
                    </>
                )
        }
    }
    render() { 
        return (
            <>
            <section className={cn(styles.webdesign)}>
                <div className={cn(styles.webdesignRow)}>
                    <div className={cn(styles.webdesignImage)}>
                        <img src={this.renderImage(this.state.city)} alt="Webdesign Image" className="img-responsive"/>
                    </div>
                    <div className={cn(styles.webdesignText)}>
                        <div className={cn(styles.webdesignTextInner)}>
                            {this.renderText1(this.state.city)}
                        </div>
                    </div>
                </div>
            </section>
            <section className={cn(styles.webdesign)}>
                <div className={cn(styles.webdesignRow)}>
                    <div className={cn(styles.webdesignImage)}>
                        <img className="img-responsive" src={googleMaps} alt="Google Maps Standort"/>
                    </div>
                    <div className={cn(styles.webdesignText)}>
                        <div className={cn(styles.webdesignTextInner)}>
                            {this.renderText2(this.state.city)}
                        </div>
                    </div>
                </div>
            </section>
            <section className={cn(styles.logos)}>
                <div className={cn(styles.logosWrapper)}>
                    <div className={cn(styles.logosItem)}>
                        <img className="img-responsive" src={googlePartner} alt="Google Partner"/>
                    </div>
                    <div className={cn(styles.logosItem)}>
                        <img className="img-responsive" src={wordpress}/>
                    </div>
                    <div className={cn(styles.logosItem)}>
                        <img className="img-responsive" src={dsgvo}/>
                    </div>
                    <div className={cn(styles.logosItem)}>
                        <a  href="https://www.provenexpert.com/erik-ivanov/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget" title="Kundenbewertungen &amp; Erfahrungen zu Erik Ivanov. Mehr Infos anzeigen." target="_blank" style={{textDecoration: 'none'}} rel="noopener noreferrer"><img src="https://images.provenexpert.com/85/97/a6407d907889604c7881aa667831/widget_landscape_180_de_0.png" alt="Erfahrungen &amp; Bewertungen zu Erik Ivanov" width="180" height="150" style={{border: '0'}} /></a>
                    </div>
                </div>
            </section>
            </>
        );
    }
}
 
export default WebdesignBlock;