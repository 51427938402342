import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import styles from '../scss/faq.module.scss';
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "Wann benötigt mein Unternehmen eine professionelle Webseite?",
            content: "<p>Wenn gute Inhalte und hochwertiges Webdesign zusammenkommen, entsteht eine professionelle Website, mit der Sie bei Kunden, Partnern und Bewerbern einen professionellen Eindruck machen. Die perfekte Grundlage um Mitarbeiter und Kunden zu überzeugen.</p><p>Wenn wir Websites erstellen, achten wir besonders auf eine einfache und verständliche Bedienung und eine hochwertige Gestaltung – entsprechend Ihren Wünschen und individuell auf das Unternehmen zugeschnitten. Mit einem einzigartigen Stil, einer konsistenten Bildsprache und einem passenden Farbkonzept, gestalten wir Ihr individuelles Homepage-Design.</p>",
        },
        {
            title: "Was bewirkt eine professionelle Webseite?",
            content: "<p>Eine Webseite kann mehr als nur gut aussehen. Lassen Sie Ihre Besucher zu Kunden werden mit ansprechenden Unterseiten. Provitieren Sie von deutlich mehr Neukunden & Sichtbarkeit im Netz. Eine strukturierte und professionell gestaltete Homepage sorgt dafür, dass sich Ihre Kunden an Sie erinnern. Jedes Design von uns ist individuell und persönlich nach Ihren Wünschen auf Sie zugeschnitten.</p>",
        },
        {
            title: "Wie wichtig ist mobiles Webdesign für meine Suchmaschinenoptimierung?",
            content: "<p>Mobiles Webdesign ist heutzutage unverzichtbar für eine erfolgreiche Suchmaschinenoptimierung. Immer mehr Nutzer greifen über mobile Geräte auf das Internet zu, und Suchmaschinen bevorzugen Websites, die für mobile Geräte optimiert sind. Eine responsive Website, die auf allen Geräten optimal angezeigt wird, kann Ihre Sichtbarkeit in den Suchergebnissen erhöhen.</p>",
        },
        {
            title: "Was ist Suchmaschinenoptimierung (SEO)?",
            content: "<p>Suchmaschinenoptimierung (SEO) ist der Prozess, bei dem bestimmte Techniken und Strategien angewendet werden, um eine Website zu verbessern und deren Platzierung in den Suchmaschinenergebnissen zu erhöhen. Es ist wichtig für Ihr Webdesign, da eine Website ohne SEO möglicherweise von Suchmaschinen nicht gefunden wird oder auf den hinteren Seiten der Ergebnisseite landet. Mit SEO können Sie sicherstellen, dass Ihre Website von den Suchmaschinen erkannt wird und höher in den Ergebnissen erscheint.</p>",
        }
    ],
};

const style = {
    bgColor: 'transparent',
    titleTextColor: "#DBAC52",
    rowTitleColor: "#333333",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() { 
        return (
            <>
            <Helmet>
                <script type="application/ld+json" dangerouslySetInnerHTML={ { __html: `{
                        "@context":"https://schema.org",
                        "@type":"FAQPage",
                        "mainEntity":[
                            {
                                "@type":"Question",
                                "name":"Wann benötigt mein Unternehmen eine professionelle Webseite?",
                                "acceptedAnswer":{
                                    "@type":"Answer",
                                    "text":"Wenn gute Inhalte und hochwertiges Webdesign zusammenkommen, entsteht eine professionelle Website, mit der Sie bei Kunden, Partnern und Bewerbern einen professionellen Eindruck machen. Die perfekte Grundlage um Mitarbeiter und Kunden zu überzeugen.  Wenn wir Websites erstellen, achten wir besonders auf eine einfache und verständliche Bedienung und eine hochwertige Gestaltung – entsprechend Ihren Wünschen und individuell auf das Unternehmen zugeschnitten. Mit einem einzigartigen Stil, einer konsistenten Bildsprache und einem passenden Farbkonzept, gestalten wir Ihr individuelles Homepage-Design."
                                }
                            },
                            {
                                "@type":"Question",
                                "name":"Was bewirkt eine professionelle Webseite?",
                                "acceptedAnswer":{
                                    "@type":"Answer",
                                    "text":"Eine Webseite kann mehr als nur gut aussehen. Lassen Sie Ihre Besucher zu Kunden werden mit ansprechenden Unterseiten. Provitieren Sie von deutlich mehr Neukunden & Sichtbarkeit im Netz. Eine strukturierte und professionell gestaltete Homepage sorgt dafür, dass sich Ihre Kunden an Sie erinnern. Jedes Design von uns ist individuell und persönlich nach Ihren Wünschen auf Sie zugeschnitten."
                                }
                            },
                            {
                                "@type":"Question",
                                "name":"Wie wichtig ist mobiles Webdesign für meine Suchmaschinenoptimierung?",
                                "acceptedAnswer":{
                                    "@type":"Answer",
                                    "text":"Mobiles Webdesign ist heutzutage unverzichtbar für eine erfolgreiche Suchmaschinenoptimierung. Immer mehr Nutzer greifen über mobile Geräte auf das Internet zu, und Suchmaschinen bevorzugen Websites, die für mobile Geräte optimiert sind. Eine responsive Website, die auf allen Geräten optimal angezeigt wird, kann Ihre Sichtbarkeit in den Suchergebnissen erhöhen."
                                }
                            },
                            {
                                "@type":"Question",
                                "name":"Was ist Suchmaschinenoptimierung (SEO)?",
                                "acceptedAnswer":{
                                    "@type":"Answer",
                                    "text":"Suchmaschinenoptimierung (SEO) ist der Prozess, bei dem bestimmte Techniken und Strategien angewendet werden, um eine Website zu verbessern und deren Platzierung in den Suchmaschinenergebnissen zu erhöhen. Es ist wichtig für Ihr Webdesign, da eine Website ohne SEO möglicherweise von Suchmaschinen nicht gefunden wird oder auf den hinteren Seiten der Ergebnisseite landet. Mit SEO können Sie sicherstellen, dass Ihre Website von den Suchmaschinen erkannt wird und höher in den Ergebnissen erscheint."
                                }
                            }
                        ]
                    }
                </script>`}} />
            </Helmet>
            <section className={cn(styles.faqSection)}>
                <div className="container">
                    <div className="headline">
                        <h2>Häufig gestellte <span>Fragen</span></h2>
                    </div>
                    <div className={cn(styles.faqWrapper)}>
                        <Faq
                            data={data}
                            styles={style}
                            config={config}
                        />
                    </div>
                </div>
            </section>
            <section className={cn(styles.webdesignSection)}>
                <div className="container">
                    <div className="headline">
                        <h2><span>Regionales Webdesign</span><br/>von Erik Ivanov</h2>
                    </div>
                    <ul className={cn(styles.linkList)}>
                        <li>
                            <Link to="/webdesign-berlin/">Webdesign Berlin</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-hamburg/">Webdesign Hamburg</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-schwerin/">Webdesign Schwerin</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-rostock/">Webdesign Rostock</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-wismar/">Webdesign Wismar</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-luebeck/">Webdesign Lübeck</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-kiel/">Webdesign Kiel</Link>
                        </li>
                        <li>
                            <Link to="/webdesign-flensburg/">Webdesign Flensburg</Link>
                        </li>
                    </ul>
                </div>
            </section>
            </>
        );
    }
}
 
export default FAQ;